var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_c('img',{staticClass:"poster",attrs:{"src":_vm.poster && _vm.$imgurl + _vm.poster,"alt":""}}),_c('img',{staticClass:"play cursor",attrs:{"src":require("@/assets/images/play.png"),"alt":""},on:{"click":_vm.playVideo}})]),_c('div',{staticClass:"block1",style:(`background:url(${
      _vm.block1.bg && _vm.$imgurl + _vm.block1.bg
    }) center/cover no-repeat;`)},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.block1.title))])]),_c('ul',{staticClass:"flex flex-wrap"},_vm._l((_vm.block1.list),function(item,index){return _c('li',{key:index,staticClass:"wow fadeInUp",attrs:{"data-wow-delay":`${index * 0.5}` + 's'}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.$imgurl + item.url,"alt":""}}),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(item.text))])])}),0)])]),_c('div',{staticClass:"block2",style:(`background:url(${
      _vm.block2.bg && _vm.$imgurl + _vm.block2.bg
    }) center/cover no-repeat ;`)},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.block2.title))])]),_c('ul',{staticClass:"flex flex-wrap"},_vm._l((_vm.block2.list),function(item,index){return _c('router-link',{key:index,staticClass:"cursor wow fadeInUp",attrs:{"tag":"li","to":{ path: '/lightcon', query: { ind: index, id: item.id } },"data-wow-delay":"0.5s"}},[_c('div',{staticClass:"filter"}),_c('div',{staticClass:"li-con flex-column-center"},[_c('p',{staticClass:"che"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"eng"},[_vm._v(_vm._s(item.entitle))])])])}),1)])]),_c('div',{staticClass:"block3",style:(`background:url(${
      _vm.block3.bg && _vm.$imgurl + _vm.block3.bg
    })  center/cover no-repeat;`)},[_c('div',{staticClass:"c-con"},[_c('img',{staticClass:"c-big",attrs:{"src":_vm.block3.c_img && _vm.$imgurl + _vm.block3.c_img,"alt":""}}),_vm._l((_vm.block3.list.slice(0, 3)),function(item,index){return _c('div',{key:index,staticClass:"abs1",on:{"click":function($event){return _vm.tolink(item.link)}}},[_c('div',{staticClass:"title b"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.content))])])})],2)]),_c('div',{staticClass:"block4",style:(`background:url(${
      _vm.block4.exp2 && _vm.$imgurl + _vm.block4.exp2.url
    }) center/cover no-repeat;`)},[_c('div',{staticClass:"con wow fadeInUp",attrs:{"data-wow-delay":".2s"}},[_c('img',{staticClass:"title",attrs:{"src":_vm.block4.photo && _vm.$imgurl + _vm.block4.photo.url,"alt":""}}),_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.block4.content)}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVideo),expression:"showVideo"}],staticClass:"video"},[_c('img',{staticClass:"close cursor",attrs:{"src":require("@/assets/images/close.png"),"alt":""},on:{"click":_vm.closeVideo}}),_c('video',{ref:"video",attrs:{"src":_vm.video && _vm.$imgurl + _vm.video,"controls":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }